import { useEffect, useRef } from "react";

import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";

import { useCheckBanners } from "@/components/HeadBanners";
import Navbar from "@/components/Navbar";
import TryForFreeButton from "@/components/TryForFreeButton";
import ChromeSvg from "@/public/chrome.svg";
import CoverBackground2Svg from "@/public/cover_background_2.svg";
import EdgeSvg from "@/public/edge.svg";
import HeadGroupImg from "@/public/pages/about/head-group.png";
import HeadGroupMobileImg from "@/public/pages/about/head-group-mobile.png";

type Props = {
  showFloatNavbar: boolean;
  setShowFloatNavbar: (showFloatNavbar: boolean) => void;
};

export default function HeadSection(props: Props) {
  // === Artifacts

  const mainContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const checkBanners = useCheckBanners();

  // === Effects

  useEffect(() => {
    const observeNavbarPos = () => {
      if (!mainContainerRef.current) {
        return;
      }

      if (mainContainerRef.current.getBoundingClientRect().top < 0) {
        if (!props.showFloatNavbar) {
          props.setShowFloatNavbar(true);
        }
      } else {
        if (props.showFloatNavbar) {
          props.setShowFloatNavbar(false);
        }
      }
    };

    window.addEventListener("scroll", observeNavbarPos);

    return () => {
      window.removeEventListener("scroll", observeNavbarPos);
    };
  }, [props]);

  // === Renderers

  return (
    <div
      className={clsx(
        "relative flex flex-col items-center w-full pt-unset md:pt-[40px]",
        "min-h-[--height-app] xl:min-h-[unset] xl:h-fit",
        {
          ["!pt-0"]: checkBanners.showBanner,
        }
      )}
    >
      {/* Background */}
      <div className="w-full h-full absolute top-0 left-0 overflow-hidden rounded-b-2xl">
        <CoverBackground2Svg
          className={"w-full h-full object-cover absolute left-0 top-0 z-0"}
          preserveAspectRatio="xMinYMin slice"
        />

        {/* Bubble bottom right */}
        <div
          style={{
            background:
              "linear-gradient(295deg, #35A7E7 11.76%, #445AE9 87.76%)",
          }}
          className={clsx(
            "rounded-full w-[40%] lg:w-[unset] lg:h-[50%] aspect-square absolute top-[20%] right-0 translate-x-[55%]"
          )}
        />

        {/* Bottom left */}
        <div
          style={{
            background:
              "linear-gradient(295deg, #35A7E7 11.76%, #445AE9 87.76%)",
          }}
          className={clsx(
            "rounded-full w-[40%] lg:w-[unset] lg:h-[25%] aspect-square absolute top-[90%] left-0 -translate-x-[30%]",
            "lg:hidden"
          )}
        />
      </div>

      {/* Floating bubbles layer */}
      <div
        className={clsx(
          "w-full h-full absolute z-20 top-0 left-0 overflow-hidden rounded-2xl",
          "pointer-events-none",
          "hidden lg:flex"
        )}
      >
        {/* Bottom left */}
        <div
          style={{
            background:
              "linear-gradient(295deg, #35A7E7 11.76%, #445AE9 87.76%)",
          }}
          className={clsx(
            "rounded-full w-[30%] lg:w-[unset] lg:h-[20%] aspect-square absolute top-[76%] left-0 -translate-x-[15%]"
          )}
        />
      </div>

      {/* Main content */}
      <div
        ref={mainContainerRef}
        className={
          "w-full static z-10 flex flex-col items-center text-[var(--color-text-light-blue)]"
        }
      >
        {/* Navbar */}
        <div className="w-full text-white">
          <Navbar isBlendIn hidden={props.showFloatNavbar} />
        </div>

        {/* Top content */}
        <div
          className={clsx(
            "flex max-w-7xl",
            "flex-col gap-8 px-6 py-8 lg:flex-row lg:items-center lg:gap-16 lg:px-20 lg:py-12"
          )}
        >
          <div className="flex flex-col max-w-1/2 gap-8">
            <h1 className="font-medium text-5xl leading-tight text-[--color-text-highlight]">
              <span>Access your bookmarks faster with </span>
              <span className="text-[--color-yellow]">memorable keywords</span>
            </h1>

            <div className={clsx("items-center gap-4", "hidden lg:flex")}>
              <TryForFreeButton
                size="bg"
                buttonLocation="HeadSection - Large Screen - Try It Free Button"
                text="Try It Free"
                className="!m-0 text-xl font-medium bg-[--color-yellow] text-black px-6 py-2 rounded-lg"
              />

              <div className="flex flex-col text-xs font-light gap-1">
                <p>Generous Freemium plan.</p>
                <p>No credit card required.</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col max-w-1/2 gap-6">
            <h2 className="text-xl">
              Struggling to find the right bookmark amid cluttered menus?
            </h2>

            <p className="font-light">
              Supercharge your bookmarking game by assigning memorable keywords
              to your links. Simply type these keywords into your browser&apos;s
              address bar to quickly access your bookmarks.
            </p>

            <div className="flex gap-4 flex-wrap">
              <TryForFreeButton
                size="bg"
                buttonLocation="HeadSection - Small Screen - Try It Free Button"
                text="Try It Free"
                className={clsx(
                  "!m-0 text-xl font-medium bg-[--color-yellow] text-black px-8 py-2 rounded-lg",
                  "flex lg:!hidden",
                  "items-center"
                )}
              />

              {/* Compatible browsers div */}
              <div
                className={clsx(
                  "flex items-center gap-4 bg-[--color-card] w-fit border-[--color-card-light] border-[1px]",
                  "px-3 py-2 rounded-xl lg:px-4 lg:py-3 lg:rounded-2xl"
                )}
              >
                <p className="text-sm italic">Compatible with</p>

                <Link
                  href={`https://chromewebstore.google.com/detail/yoyo-shortcut/${process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID}`}
                  target="_blank"
                >
                  <ChromeSvg className="w-8 h-auto" />
                </Link>

                <Link
                  href={`https://chromewebstore.google.com/detail/yoyo-shortcut/${process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID}`}
                  target="_blank"
                >
                  <EdgeSvg className="w-8 h-auto" />
                </Link>
              </div>
            </div>

            <p className="flex text-sm font-light lg:hidden">
              Generous Freemium plan. No credit card required.
            </p>
          </div>
        </div>

        {/* Image when big screen */}
        <div
          className={clsx("max-w-7xl justify-center w-full", "hidden lg:flex")}
        >
          <Image
            className="w-[90%]"
            alt={"Head Group Image"}
            src={HeadGroupImg.src}
            width={HeadGroupImg.width}
            height={HeadGroupImg.height}
          />
        </div>

        {/* Image when small screen */}
        <div
          className={clsx("max-w-7xl justify-center w-full", "flex lg:hidden")}
        >
          <Image
            className="w-[90%]"
            alt={"Head Group Image"}
            src={HeadGroupMobileImg.src}
            width={HeadGroupMobileImg.width}
            height={HeadGroupMobileImg.height}
          />
        </div>
      </div>
    </div>
  );
}
