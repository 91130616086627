import { useEffect, useState } from "react";

import axios from "axios";
import QueryStringCenter from "cross-platform-resources/dist/front-end/QueryStringCenter";
import { getParams } from "cross-platform-resources/dist/front-end/Router";
import { NextPage } from "next";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";

import FeatureSection from "@/components/_pages/about/FeatureSection";
import GetStartedSection from "@/components/_pages/about/GetStartedSection";
import HeadSection from "@/components/_pages/about/HeadSection";
import TestimonialSection from "@/components/_pages/about/TestimonialSection";
import TransformBrowse from "@/components/_pages/about/TransformBrowse";
import Navbar from "@/components/Navbar";
import DiscordFilled from "@/public/discord-filled.svg";
import Email from "@/public/email.svg";
import Facebook from "@/public/facebook.svg";
import Medium from "@/public/medium.svg";
import { sentryReport } from "@/utils/Error";

const About: NextPage = () => {
  const router = useRouter();
  const [showFloatNavbar, setShowFloatNavbar] = useState(false);
  const [logView, setLogView] = useState(false);

  useEffect(() => {
    if (!router.isReady || logView) {
      return;
    }

    const referrer = getParams(router, [QueryStringCenter.about.REFERRER])[
      QueryStringCenter.about.REFERRER
    ];

    // Use timeout to avoid a weird bug of Too Many Requests when changing pages (from Pricing to About for example)
    const timeout = window.setTimeout(() => {
      axios({
        method: "post",
        url: `${process.env.NEXT_PUBLIC_BACKEND_HOST}/log/view-landing-page`,
        data: {
          referrer: referrer ?? undefined,
        },
      })
        .then(() => {
          setLogView(true);
        })
        .catch((err) => {
          sentryReport(err);
        });
    }, 1000);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [JSON.stringify(router), logView]);

  return (
    <div className="flex flex-col items-center bg-[var(--color-background)] text-white min-h-[--height-app] min-w-screen">
      <Head>
        <title>Yoyo Shortcut</title>
        <meta
          name="description"
          content="Fast shortcuts to your favorite sites"
        />
        <link rel="icon" href="/logo.svg" />
      </Head>

      <Navbar isFloat hidden={!showFloatNavbar} />

      <main className="flex flex-col items-center w-full">
        <HeadSection
          showFloatNavbar={showFloatNavbar}
          setShowFloatNavbar={setShowFloatNavbar}
        />

        <FeatureSection />

        <TestimonialSection />

        <GetStartedSection />

        <TransformBrowse />

        <div className="flex flex-col lg:flex-row-reverse w-full max-w-[var(--max-width-app)]">
          <div className="flex flex-row justify-center items-center gap-x-3 gap-y-3 text-[var(--color-text-secondary)] text-sm">
            <Link href="https://discord.gg/PjXhfGcDgP" target="_blank">
              <DiscordFilled />
            </Link>
            <Link
              href="https://www.facebook.com/yoyoshortcut.official"
              target="_blank"
            >
              <Facebook />
            </Link>
            <Link href="https://medium.com/yoyoshortcut" target="_blank">
              <Medium />
            </Link>
            <Link href="mailto:hello@yoyoshortcut.com" target="_blank">
              <Email />
            </Link>
          </div>
          <div className="flex flex-wrap w-full justify-center lg:justify-start gap-x-2 gap-y-3 py-6 px-6 lg:px-0 text-[var(--color-text-secondary)] text-sm">
            <p>© 2025 Yoyo Shortcut</p>
            <p>●</p>

            <Link
              href="/policies/privacy"
              target="_blank"
              className="hover:underline"
            >
              Privacy
            </Link>
            <p>●</p>

            <Link
              href="/policies/terms"
              target="_blank"
              className="hover:underline"
            >
              Terms
            </Link>
            <p>●</p>

            <Link
              href="/policies/cookies"
              target="_blank"
              className="hover:underline"
            >
              Cookies
            </Link>
            <p>●</p>

            <div
              onClick={() => {
                window.displayPreferenceModal();
              }}
              id="termly-consent-preferences"
              className="hover:underline cursor-pointer"
            >
              Consent Preferences
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default About;
